// Copyright © 2021 Move Closer

import { Authentication, AuthServiceType, EventbusType, EventPayload, IEventbus } from '@movecloser/front-core'
import { Component, Vue } from 'vue-property-decorator'

import { Inject } from '@plugin/inversify'

import {
  canCreateContents,
  canCreateSets,
  canDeleteContents,
  canDeleteSets,
  canEditContents,
  canEditSets,
  canEditSettings,
  canManageContentsComments,
  canPublishContents,
  canSendWebPushContents,
  canViewContents,
  canViewContentsComments,
  canViewSets
} from '@module/content/guards'
import { canDeleteMedia, canEditMedia, canUploadMedia, canViewMedia } from '@module/media/guards'
import {
  canCreateLayout,
  canCreateNavigation,
  canDeleteLayout,
  canDeleteNavigation,
  canEditLayout,
  canEditNavigation,
  canViewLayout,
  canViewNavigation
} from '@module/settings/guards'
import {
  canCreateAuthors,
  canCreateRoles,
  canCreateSubscription,
  canCreateUsers,
  canDeleteAuthors,
  canDeleteRoles,
  canDeleteUsers,
  canEditAuthors,
  canEditRoles,
  canEditSubscription,
  canEditUsers,
  canViewAuthors,
  canViewRoles,
  canViewSubscription,
  canViewUsers
} from '@module/users/guards'
import { Domain } from '@module/root/contracts/models'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'

import { UserModel } from '../contracts/models'

export interface IUserAware {
  canCreateAuthors: boolean
  canDeleteAuthors: boolean
  canEditAuthors: boolean
  canViewAuthors: boolean
  canViewSets: boolean
  canCreateSets: boolean
  canEditSets: boolean
  canDeleteSets: boolean
  canCreateContents: boolean
  canEditContents: boolean
  canDeleteContents: boolean
  canManageContentsComments: boolean
  canViewContentsComments: boolean
  canPublishContents: boolean
  canSendWebPushContents: boolean
  canViewContents: boolean
  canEditMedia: boolean
  canUploadMedia: boolean
  canDeleteMedia: boolean
  canViewMedia: boolean
  canViewNavigation: boolean
  canCreateNavigation: boolean
  canDeleteNavigation: boolean
  canCreateUsers: boolean
  canEditUsers: boolean
  canDeleteUsers: boolean
  canViewUsers: boolean
  canCreateRoles: boolean
  canEditRoles: boolean
  canDeleteRoles: boolean
  canViewRoles: boolean
  canEditLayout: boolean
  canViewLayout: boolean
  canCreateLayout: boolean
  canDeleteLayout: boolean
  canViewSubscription: boolean
  canSyncSeoData: boolean
  domain: Domain
  user: UserModel | null
}

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<UserAware>({
  created () {
    this.domain = this.siteResolver.getSite()?.domain || Domain.Defence24PL
    this.user = this.authService.user

    this.eventBus.handle('ui:context.changed', () => {
      this.domain = this.siteResolver.getSite()?.domain || Domain.Defence24PL
    })

    this.eventBus.handle('app:authentication', (event: EventPayload<string>) => {
      this.onAuthentication(event)
      this.resolveUser()
    })
  }
})
export class UserAware extends Vue implements IUserAware {
  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(EventbusType)
  private eventBus!: IEventbus

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public domain: Domain = Domain.Defence24PL
  public user: UserModel | null = null

  /**
   * Authors permissions
   */
  public get canCreateAuthors () {
    return canCreateAuthors(this.domain, this.authService.user)
  }

  public get canDeleteAuthors () {
    return canDeleteAuthors(this.domain, this.authService.user)
  }

  public get canEditAuthors () {
    return canEditAuthors(this.domain, this.authService.user)
  }

  public get canViewAuthors () {
    return canViewAuthors(this.domain, this.authService.user)
  }

  /**
   * Contents permissions
   */
  public get canCreateContents () {
    return canCreateContents(this.domain, this.authService.user)
  }

  public get canEditContents () {
    return canEditContents(this.domain, this.authService.user)
  }

  public get canDeleteContents () {
    return canDeleteContents(this.domain, this.authService.user)
  }

  public get canManageContentsComments () {
    return canManageContentsComments(this.domain, this.authService.user)
  }

  public get canPublishContents () {
    return canPublishContents(this.domain, this.authService.user)
  }

  public get canSendWebPushContents () {
    return canSendWebPushContents(this.domain, this.authService.user)
  }

  public get canViewContents () {
    return canViewContents(this.domain, this.authService.user)
  }

  public get canViewContentsComments () {
    return canViewContentsComments(this.domain, this.authService.user)
  }

  public get canSyncSeoData () {
    return canEditSettings(this.domain, this.authService.user)
  }

  /**
   * Sets permissions
   */
  public get canViewSets () {
    return canViewSets(this.domain, this.authService.user)
  }

  public get canCreateSets () {
    return canCreateSets(this.domain, this.authService.user)
  }

  public get canEditSets () {
    return canEditSets(this.domain, this.authService.user)
  }

  public get canDeleteSets () {
    return canDeleteSets(this.domain, this.authService.user)
  }

  /**
   * Media permissions
   */
  public get canUploadMedia () {
    return canUploadMedia(this.domain, this.authService.user)
  }

  public get canDeleteMedia () {
    return canDeleteMedia(this.domain, this.authService.user)
  }

  public get canEditMedia () {
    return canEditMedia(this.domain, this.authService.user)
  }

  public get canViewMedia () {
    return canViewMedia(this.domain, this.authService.user)
  }

  /**
   * Navigation permissions
   */
  public get canViewNavigation () {
    return canViewNavigation(this.domain, this.authService.user)
  }

  public get canCreateNavigation () {
    return canCreateNavigation(this.domain, this.authService.user)
  }

  public get canEditNavigation () {
    return canEditNavigation(this.domain, this.authService.user)
  }

  public get canDeleteNavigation () {
    return canDeleteNavigation(this.domain, this.authService.user)
  }

  /**
   * Users permissions
   */
  public get canCreateUsers () {
    return canCreateUsers(this.domain, this.authService.user)
  }

  public get canEditUsers () {
    return canEditUsers(this.domain, this.authService.user)
  }

  public get canDeleteUsers () {
    return canDeleteUsers(this.domain, this.authService.user)
  }

  public get canViewUsers () {
    return canViewUsers(this.domain, this.authService.user)
  }

  /**
   * Roles permissions
   */
  public get canCreateRoles () {
    return canCreateRoles(this.domain, this.authService.user)
  }

  public get canEditRoles () {
    return canEditRoles(this.domain, this.authService.user)
  }

  public get canDeleteRoles () {
    return canDeleteRoles(this.domain, this.authService.user)
  }

  public get canViewRoles () {
    return canViewRoles(this.domain, this.authService.user)
  }

  /**
   * Layout permissions
   */
  public get canViewLayout () {
    return canViewLayout(this.domain, this.authService.user)
  }

  public get canCreateLayout () {
    return canCreateLayout(this.domain, this.authService.user)
  }

  public get canEditLayout () {
    return canEditLayout(this.domain, this.authService.user)
  }

  public get canDeleteLayout () {
    return canDeleteLayout(this.domain, this.authService.user)
  }

  /**
   * Subscription permissions
   */
  public get canViewSubscription () {
    return canViewSubscription(this.domain, this.authService.user)
  }

  public get canEditSubscription () {
    return canEditSubscription(this.domain, this.authService.user)
  }

  public get canCreateSubscription () {
    return canCreateSubscription(this.domain, this.authService.user)
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onAuthentication (event: EventPayload<string>): void {
  }

  protected resolveUser (): void {
    this.user = this.authService.user
  }
}
