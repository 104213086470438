// Copyright © 2021 Move Closer

import { ModalRegistry } from '@movecloser/front-core'
import { Picker } from '@d24/modules'
import { VueConstructor } from 'vue'

import { ConfirmModal } from '@component/ConfirmModal'
import { ModalConfiguration, ModalSize } from '@component/Modal'
import { SelectModuleModal } from '@component/PageBuilder'

import AddDatasetModal from '@module/content/components/AddDatasetModal.vue'
import AddFormModal from '@module/forms/components/AddFormModal.vue'
import AddSetItemModal from '@module/content/components/AddSetItemModal.vue'
import ArticlePickerModal from '@module/content/components/ArticlePickerModal.vue'
import ArticlesListModal from '@module/settings/components/TagContentListModal.vue'
import AuthorPicker from '@module/users/components/AuthorPicker.vue'
import ConfirmAcceptanceModal from '@module/content/components/ComfirmAcceptanceModal.vue'
import ContentPickerModal from '@module/content/components/ContentPickerModal.vue'
import ContentTakeOverModal from '@module/content/components/ContentTakeOverModal.vue'
import CropImageModal from '@module/media/components/CropImageModal.vue'
import DirectoryCreateModal from '@module/media/components/DirectoryCreateModal.vue'
import DirectoryEditModal from '@module/media/components/DirectoryEditModal.vue'
import DirectoryMoveModal from '@module/media/components/DirectoryMoveModal.vue'
import EditFilesDataModal from '@module/media/components/EditFilesDataModal.vue'
import EmbedFormModal from '@component/EmbedModalForm/EmbedModalForm.vue'
import FileCreateModal from '@module/media/components/FileCreateModal.vue'
import FileEditModal from '@module/media/components/FileEditModal.vue'
import FilePickerModal from '@module/media/components/FilePickerModal.vue'
import FilesMoveModal from '@module/media/components/FilesMoveModal.vue'
import FootnoteModal from '@module/content/components/FootnoteModal'
import ForceSiteChangeModal from '@module/root/components/ForceSiteChangeModal.vue'
import GalleryPickerModal from '@module/media/components/GalleryPickerModal.vue'
import GeneratedNewsletterModal from '@module/content/components/GeneratedNewsletterModal.vue'
import LinkPickerModal from '@module/content/components/LinkPickerModal.vue'
import LockConflictModal from '@module/content/components/LockConflictModal.vue'
import LoginModal from '@module/auth/components/LoginModal.vue'
import MarkdownEditorGuideModal from '@module/content/components/MarkdownEditorGuideModal'
import MetaAddonModal from '@module/content/components/addons/MetaAddonModal.vue'
import PageListsModal from '@module/content/components/PageListsModal.vue'
import PreventLoosingDataModal from '@module/content/components/PreventLoosingDataModal.vue'
import QuoteFormModal from '@component/QuoteFormModal/QuoteFormModal.vue'
import ResetListItemsModal from '@module/content/components/ResetListItemsModal.vue'
import RemoveConflictModal from '@module/content/components/RemoveConflictModal.vue'
import SelectUserModal from '@module/users/components/SelectUserModal.vue'
import SetPickerModal from '@module/content/components/SetPickerModal.vue'
import SiteSelectionModal from '@module/root/components/SiteSelectionModal.vue'
import SortContentModal from '@module/content/components/SortContentModal.vue'
import TagModal from '@module/settings/components/TagModal.vue'
import TransactionDetailsModal from '@module/users/components/TransactionDetailsModal.vue'
import TransactionModal from '@module/users/components/TranactionModal.vue'
import VariantPreviewDecisionModal from '@module/content/components/VariantPreviewDecisionModal.vue'
import VariantEnableAbTestsModal from '@module/content/components/VariantEnableAbTestsModal.vue'
import VariantAddAbTestsModal from '@module/content/components/VariantAddAbTestsModal.vue'
import VariantCompleteAbTestsModal from '@module/content/components/VariantCompleteAbTestsModal.vue'

export enum Modals {
  AddDataset = 'addDataset',
  AddForm = 'addForm',
  AddSetItem = 'addSetItem',
  ArticlesList = 'articlesList',
  Confirm = 'confirm',
  ConfirmAcceptance = 'confirmAcceptance',
  ContentLists = 'contentLists',
  ContentTakeOver = 'contentTakeOver',
  CropImage = 'cropImage',
  DirectoryCreate = 'directoryCreate',
  DirectoryEdit = 'directoryEdit',
  DirectoryMove = 'directoryMove',
  EditFileData = 'editFileData',
  EmbedModal = 'embedModal',
  FileCreate = 'fileCreate',
  FileEdit = 'fileEdit',
  FilesMove = 'filesMove',
  Footnote = 'footnote',
  ForceChangeSite = 'forceChangeSite',
  GeneratedNewsletter = 'generatedNewsletter',
  LockConflict = 'lockConflict',
  Login = 'loginModal',
  MarkdownEditorGuide = 'markdownEditorGuide',
  MetaAddonModal = 'metaAddon',
  PickArticle = 'pickArticle',
  PickContent = 'pickContent',
  PickFile = 'pickFile',
  PickGallery = 'pickGallery',
  PickLink = 'pickLink',
  PickSet = 'pickSet',
  PreventLoosingData = 'preventLoosingDataModal',
  QuoteFormModal = 'quoteFormModal',
  RemoveConflictModal = 'removeConflictModal',
  ResetListItemsModal = 'resetListItemsModal',
  SelectModuleModal = 'selectModuleModal',
  SelectUserModal = 'selectUserModal',
  SiteSelection = 'siteSelection',
  SortContent = 'sortContent',
  Tag = 'tagModal',
  TransactionDetailsModal = 'transactionDetailsModal',
  TransactionModal = 'transactionModal',
  VariantPreviewDecision = 'variantPreviewDecision',
  VariantEnableAbTests = 'variantEnableAbTests',
  VariantAddAbTests = 'variantAddAbTests',
  VariantCompleteAbTests = 'variantCompleteAbTests'
}

/**
 * Registry of available modals.
 */
export const modals: ModalRegistry<VueConstructor> = {
  [Modals.AddDataset]: AddDatasetModal,
  [Modals.AddForm]: AddFormModal,
  [Modals.AddSetItem]: AddSetItemModal,
  [Modals.ArticlesList]: ArticlesListModal,
  [Modals.ConfirmAcceptance]: ConfirmAcceptanceModal,
  [Modals.Confirm]: ConfirmModal,
  [Modals.ContentLists]: PageListsModal,
  [Modals.ContentTakeOver]: ContentTakeOverModal,
  [Modals.CropImage]: CropImageModal,
  [Modals.DirectoryCreate]: DirectoryCreateModal,
  [Modals.DirectoryEdit]: DirectoryEditModal,
  [Modals.DirectoryMove]: DirectoryMoveModal,
  [Modals.EditFileData]: EditFilesDataModal,
  [Modals.EmbedModal]: EmbedFormModal,
  [Modals.FileCreate]: FileCreateModal,
  [Modals.FileEdit]: FileEditModal,
  [Modals.FilesMove]: FilesMoveModal,
  [Modals.GeneratedNewsletter]: GeneratedNewsletterModal,
  [Modals.Footnote]: FootnoteModal,
  [Modals.ForceChangeSite]: ForceSiteChangeModal,
  [Modals.LockConflict]: LockConflictModal,
  [Modals.Login]: LoginModal,
  [Modals.MarkdownEditorGuide]: MarkdownEditorGuideModal,
  [Modals.MetaAddonModal]: MetaAddonModal,
  [Modals.PickArticle]: ArticlePickerModal,
  [Modals.PickFile]: FilePickerModal,
  [Modals.PickGallery]: GalleryPickerModal,
  [Modals.PickLink]: LinkPickerModal,
  [Modals.PreventLoosingData]: PreventLoosingDataModal,
  [Modals.QuoteFormModal]: QuoteFormModal,
  [Modals.ResetListItemsModal]: ResetListItemsModal,
  [Modals.RemoveConflictModal]: RemoveConflictModal,
  [Modals.SelectModuleModal]: SelectModuleModal,
  [Modals.SelectUserModal]: SelectUserModal,
  [Modals.SiteSelection]: SiteSelectionModal,
  [Modals.SortContent]: SortContentModal,
  [Modals.Tag]: TagModal,
  [Modals.TransactionDetailsModal]: TransactionDetailsModal,
  [Modals.TransactionModal]: TransactionModal,
  [Modals.VariantPreviewDecision]: VariantPreviewDecisionModal,
  [Modals.VariantEnableAbTests]: VariantEnableAbTestsModal,
  [Modals.VariantAddAbTests]: VariantAddAbTestsModal,
  [Modals.VariantCompleteAbTests]: VariantCompleteAbTestsModal,
  [Picker.Author]: AuthorPicker,
  [Picker.Content]: ContentPickerModal,
  [Picker.Gallery]: GalleryPickerModal,
  [Picker.Media]: FilePickerModal,
  [Picker.Set]: SetPickerModal
}

/**
 * Default config for modals.
 */
export const modalConfig: ModalConfiguration = {
  closableWithOutsideClick: false,
  height: ModalSize.auto,
  width: ModalSize.sm
}
