



























import { Component, Prop, Vue } from 'vue-property-decorator'

import { VariantEnableAbTestsModalPayload } from '../contracts'

@Component({
  name: 'VariantEnableAbTestsModal'
})
export class VariantEnableAbTestsModal extends Vue {
  @Prop({ type: Object, required: true })
  protected payload!: VariantEnableAbTestsModalPayload

  public form = {
    name: ''
  }

  public get isNameValid (): boolean {
    return this.form.name.length > 0 && this.form.name.length <= 50
  }

  public get isValid (): boolean {
    return this.isNameValid
  }

  public onConfirm (): void {
    this.$emit('close')
    this.payload.onConfirm({
      name: this.form.name
    })
  }

  public closeModal (): void {
    this.$emit('close')
    this.payload.onCancel()
  }
}

export default VariantEnableAbTestsModal
