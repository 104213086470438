// Copyright © 2021 Move Closer

import { ICollection } from '@movecloser/front-core'

import { Query } from '@/shared/contracts/query'
import {
  DictModel,
  SetupData,
  SiteModel
} from './models'
import { Identifier } from '@/shared/contracts/data'

export interface IDictionaryRepository {
  loadAuthor (id: Identifier): Promise<DictModel>
  loadAuthorsDictionary (query: Query): Promise<ICollection<DictModel>>
  loadGalleryDictionary (query: Query): Promise<ICollection<DictModel>>
  loadLabelsDictionary (): Promise<ICollection<DictModel>>
  loadLayoutsDictionary (query: Query): Promise<ICollection<DictModel>>
  loadTag (id: Identifier): Promise<DictModel>
  loadTagsDictionary (query: Query): Promise<ICollection<DictModel>>
  loadRolesDictionary (query: Query): Promise<ICollection<DictModel>>
  loadUser (id: Identifier): Promise<DictModel>
  loadUsersDictionary (query: Query): Promise<ICollection<DictModel>>
}

export const DictionaryRepositoryType = Symbol.for('IDictionaryRepository')

export interface ISitesRepository {
  loadCollection (): Promise<ICollection<SiteModel>>

  setup (): Promise<SetupData>
}

export const SitesRepositoryType = Symbol.for('ISitesRepository')
