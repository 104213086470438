





















































































































































import { BreadcrumbsProps, DashmixIconName, DashmixTheme } from '@d24/modules'
import { Component, Vue } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import { FormInput } from '@component/form'
import { Loader } from '@component/Loader'
import { EditModeLayout } from '@component/EditModeLayout'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'
import { Property } from '@component/Property'
import { ConfirmModalPayload } from '@component/ConfirmModal'

import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'
import { ISitesRepository, SitesRepositoryType } from '@module/root/contracts/repositories'
import { SiteModel } from '@module/root/contracts/models'
import { ICollection, IModal, ModalType } from '@movecloser/front-core'
import {
  ContentModel,
  ContentRepositoryType,
  ContentType,
  IContentRepository,
  IRegistryRepository,
  Properties,
  RegistryRepositoryType
} from '@module/content/contracts'
import { Modals } from '@/config/modals'

export interface UrlElement {
  path: string
  siteId: number
  check: boolean
  ads: boolean
  adsAdX: boolean
  adsAdXAdSense: boolean
  url?: string
  content?: ContentModel
}

@Component<AdsXEdit>({
  name: 'AdsXEdit',
  components: {
    EditModeLayout,
    FormInput,
    Loader,
    ModelListHeader,
    Property
  }
})
export class AdsXEdit extends Vue {
  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(ContentRepositoryType)
  private contentRepository!: IContentRepository

  @Inject(RegistryRepositoryType)
  private registryRepository!: IRegistryRepository

  @Inject(SitesRepositoryType)
  private sitesRepository!: ISitesRepository

  public isLoading = false
  public checkAllToggle = false
  public plain = ''
  public icons = DashmixIconName
  public urls: Record<string, UrlElement[]> = {}
  public sites: ICollection<SiteModel> | null = null
  public sitesMap: Record<string, SiteModel> | null = null
  public listType: string | null = null
  public listTypeProps: Properties | null = null

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: 'AdsX Link resolver',
          target: { name: 'settings.ads.adx' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public checkAll () {
    this.checkAllToggle = true
    if (!this.urls) return
    Object.keys(this.urls).forEach((key) => {
      if (this.urls && this.urls[key]) {
        this.urls[key].forEach((registry) => {
          registry.check = !!registry.content
        })
      }
    })
  }

  public unCheckAll () {
    this.checkAllToggle = false
    if (!this.urls) return
    Object.keys(this.urls).forEach((key) => {
      if (this.urls && this.urls[key]) {
        this.urls[key].forEach((registry) => {
          registry.check = false
        })
      }
    })
  }

  public blockAdsForChecked () {
    const modalPayload: ConfirmModalPayload = {
      content: {
        buttonLabel: String(this.$t('content.ads.block_all.button')),
        contentText: String(this.$t('content.ads.block_all.text')),
        header: String(this.$t('content.ads.block_all.header')),
        theme: DashmixTheme.Danger
      },
      onConfirm: () => this.bulkChange({ ads: false })
    }
    this.modalConnector.open(Modals.Confirm, modalPayload)
  }

  public unBlockAdsForChecked () {
    const modalPayload: ConfirmModalPayload = {
      content: {
        buttonLabel: String(this.$t('content.ads.unblock_all.button')),
        contentText: String(this.$t('content.ads.unblock_all.text')),
        header: String(this.$t('content.ads.unblock_all.header')),
        theme: DashmixTheme.Success
      },
      onConfirm: () => this.bulkChange({ ads: true })
    }
    this.modalConnector.open(Modals.Confirm, modalPayload)
  }

  public blockAdxForChecked () {
    const modalPayload: ConfirmModalPayload = {
      content: {
        buttonLabel: String(this.$t('content.ads.block_all.button')),
        contentText: String(this.$t('content.ads.block_all.text')),
        header: String(this.$t('content.ads.block_all.header')),
        theme: DashmixTheme.Danger
      },
      onConfirm: () => this.bulkChange({ adsAdX: false })
    }
    this.modalConnector.open(Modals.Confirm, modalPayload)
  }

  public unBlockAdxForChecked () {
    const modalPayload: ConfirmModalPayload = {
      content: {
        buttonLabel: String(this.$t('content.ads.unblock_all.button')),
        contentText: String(this.$t('content.ads.unblock_all.text')),
        header: String(this.$t('content.ads.unblock_all.header')),
        theme: DashmixTheme.Success
      },
      onConfirm: () => this.bulkChange({ adsAdX: true })
    }
    this.modalConnector.open(Modals.Confirm, modalPayload)
  }

  public blockAdxSForChecked () {
    const modalPayload: ConfirmModalPayload = {
      content: {
        buttonLabel: String(this.$t('content.ads.block_all.button')),
        contentText: String(this.$t('content.ads.block_all.text')),
        header: String(this.$t('content.ads.block_all.header')),
        theme: DashmixTheme.Danger
      },
      onConfirm: () => this.bulkChange({ adsAdXAdSense: false })
    }
    this.modalConnector.open(Modals.Confirm, modalPayload)
  }

  public unBlockAdxSForChecked () {
    const modalPayload: ConfirmModalPayload = {
      content: {
        buttonLabel: String(this.$t('content.ads.unblock_all.button')),
        contentText: String(this.$t('content.ads.unblock_all.text')),
        header: String(this.$t('content.ads.unblock_all.header')),
        theme: DashmixTheme.Success
      },
      onConfirm: () => this.bulkChange({ adsAdXAdSense: true })
    }
    this.modalConnector.open(Modals.Confirm, modalPayload)
  }

  public bulkChange (data: Properties) {
    console.log('bulk', data)
    this.modalConnector.close()
    if (!this.urls) return
    this.isLoading = true

    Object.keys(this.urls).forEach((key) => {
      if (this.urls && this.urls[key]) {
        this.urls[key].forEach((registry) => {
          if (registry.check) {
            if (registry.content) {
              this.changeAdSettings(registry.content, data, false)
            }
          }
        })
      }
    })
    setTimeout(() => {
      this.resolveList()
      this.isLoading = false
    }, 1000)
  }

  public changeAdSettings (content: ContentModel, properties: Properties, refresh = true) {
    console.log('changeAdSettings', content.id, { ...content.properties, ...properties })
    this.contentRepository.properties(content.id, { ...content.properties, ...properties }).then(() => {
      if (refresh) {
        this.resolveList()
      }
    })
  }

  public async mounted () {
    this.isLoading = true
    await this.sitesRepository.loadCollection().then((response) => {
      this.isLoading = false
      this.sites = response
      this.sites.map(site => {
        this.sitesMap = {
          ...this.sitesMap,
          [site.domain]: site
        }
        this.urls[site.domain] = []
      })
    })
  }

  public listAdXResolve (params: Properties) {
    this.listType = 'listAdXResolve'
    this.listTypeProps = params

    if (this.sitesMap === null) return
    this.isLoading = true
    const mapUrlsByService: Record<string, UrlElement[]> = {}
    if (this.sites) {
      this.sites.map(site => {
        mapUrlsByService[site.domain] = []
      })
    }
    this.contentRepository.loadCollection([ContentType.Article, ContentType.LiveArticle], {
      ...{
        perPage: '1000',
        allSites: '1',
        page: '1'
      },
      ...params
    }).then((response) => {
      response.forEach((content) => {
        if (!mapUrlsByService[content.site.domain]) {
          mapUrlsByService[content.site.domain] = []
        }
        mapUrlsByService[content.site.domain].push({
          path: content.title,
          siteId: ((this.sitesMap && this.sitesMap[content.site.domain]) ? this.sitesMap[content.site.domain].id : 0),
          check: false,
          ads: content.properties.ads as boolean,
          adsAdX: content.properties.adsAdX as boolean,
          adsAdXAdSense: content.properties.adsAdXAdSense as boolean,
          content: content,
          url: content.url || ''
        })
      })
      this.urls = mapUrlsByService
      this.isLoading = false
    })
  }

  public resolveList () {
    if (this.listType === 'plainResolve') {
      this.plainResolve()
    }
    if (this.listType === 'listAdXResolve' && this.listTypeProps) {
      this.listAdXResolve(this.listTypeProps)
    }
  }

  public plainResolve () {
    this.listType = 'plainResolve'
    const urls = this.plain.split('\n') as string[]
    const urlsObjects = urls.map(url => url.trim()).filter(url => url.length > 0).map(url => {
      if (url.startsWith('http://') || url.startsWith('https://')) {
        return url
      } else {
        return `https://${url}`
      }
    }).map(url => {
      const tmpUrl = new URL(url)
      return {
        host: tmpUrl.host,
        pathname: tmpUrl.pathname
      }
    }).filter(url => url.pathname.length > 0 && url.host.length > 0 && url.pathname !== '/')
    const mapUrlsByService: Record<string, UrlElement[]> = {}

    urlsObjects.forEach(url => {
      if (url.pathname.startsWith('/')) {
        url.pathname = url.pathname.substr(1)
      }
      if (mapUrlsByService[url.host]) {
        mapUrlsByService[url.host].push({
          path: url.pathname,
          siteId: ((this.sitesMap && this.sitesMap[url.host]) ? this.sitesMap[url.host].id : 0),
          check: false,
          ads: false,
          adsAdX: false,
          adsAdXAdSense: false,
          content: undefined,
          url: undefined
        })
      } else {
        mapUrlsByService[url.host] = [
          {
            path: url.pathname,
            siteId: ((this.sitesMap && this.sitesMap[url.host]) ? this.sitesMap[url.host].id : 0),
            check: false,
            ads: false,
            adsAdX: false,
            adsAdXAdSense: false,
            content: undefined,
            url: undefined
          }
        ]
      }
    })
    this.urls = mapUrlsByService

    const paths: string[] = []
    urlsObjects.forEach(url => {
      paths.push(url.pathname)
    })
    Object.keys(mapUrlsByService).forEach((host) => {
      const siteId = ((this.sitesMap && this.sitesMap[host]) ? this.sitesMap[host].id : 0)
      this.registryRepository.loadCollection({
        // eslint-disable-next-line @typescript-eslint/camelcase
        site_id: siteId as unknown as string,
        address: paths,
        perPage: '1000',
        page: '1'
      }).then((response) => {
        response.forEach((registry) => {
          this.urls && this.urls[host] && this.urls[host].forEach((el, elKey) => {
            if (el.path === registry.address && this.urls && this.urls[host][elKey]) {
              this.urls[host][elKey].url = 'https://' + host + '/' + registry.address
              this.urls[host][elKey].ads = registry.content.properties.ads as boolean
              this.urls[host][elKey].adsAdX = registry.content.properties.adsAdX as boolean
              this.urls[host][elKey].adsAdXAdSense = registry.content.properties.adsAdXAdSense as boolean
              this.urls[host][elKey].content = registry.content
            }
          })
        })
      })
    })
  }
}

export default AdsXEdit
