

























import { Component, Prop, Vue } from 'vue-property-decorator'

import { VariantCompleteAbTestsModalPayload } from '../contracts'

@Component({
  name: 'VariantCompleteAbTestsModal'
})
export class VariantCompleteAbTestsModal extends Vue {
  @Prop({ type: Object, required: true })
  protected payload!: VariantCompleteAbTestsModalPayload

  public form = {
    variant: ''
  }

  public get isVariantValid (): boolean {
    return this.form.variant.length === 1
  }

  public get isValid (): boolean {
    return this.isVariantValid
  }

  public onConfirm (): void {
    this.$emit('close')
    this.payload.onConfirm({
      variant: this.form.variant
    })
  }

  public closeModal (): void {
    this.$emit('close')
    this.payload.onCancel()
  }
}

export default VariantCompleteAbTestsModal
