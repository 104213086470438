










































































































































import { Component, Prop, PropSync } from 'vue-property-decorator'
import { DashmixIconName, DashmixSelectItem } from '@d24/modules'

import ConfigForm from '../components/_abstract/ConfigForm.vue'

import { GeneralSettings, GeneralSettingsData } from '../contracts'

@Component({
  name: 'GeneralSettingsForm'
})
export class GeneralSettingsForm extends ConfigForm {
  @Prop({
    type: Boolean,
    required: false
  })
  public isLoading!: boolean

  @PropSync('formData', {
    type: Object,
    required: true
  })
  public payload!: GeneralSettingsData

  public icons = DashmixIconName
  public formName = 'hotJar'

  /**
   * General settings list
   */
  public get generalSettings (): { label: string; value: string }[] {
    return [...Object.entries(GeneralSettings).map(([label, value]) => {
      return {
        label,
        value
      }
    })]
  }

  /**
   * General Settings options list
   *
   * @example true | false | null
   *
   * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
   */
  public get generalSettingsOptions (): DashmixSelectItem[] {
    return [
      {
        label: String(this.$t('general.options.check')),
        value: true
      },
      {
        label: String(this.$t('general.options.uncheck')),
        value: false
      },
      {
        label: String(this.$t('general.options.not-change')),
        value: null
      }
    ]
  }

  /**
   * General Free Trial Transaction Types
   */
  public get generalFreeTrialTransactionTypes (): DashmixSelectItem[] {
    return [
      {
        label: String(this.$t('general.generalFreeTrialTransactionTypes.zero')),
        value: 'zero'
      },
      {
        label: String(this.$t('general.generalFreeTrialTransactionTypes.refund')),
        value: 'refund'
      }
    ]
  }

  /**
   * @inheritDoc
   */
  public static defaultPayload (): GeneralSettingsData {
    return {
      allowComments: false,
      ads: false,
      lockCommenting: false,
      hideAuthor: false,
      hidePublicationDate: false,
      hideSubscriptionButton: false,
      premiumLink: '',
      hjId: '',
      noIndexNoFollow: false,
      paywall: false,
      autoAdTags: false,
      paywallOptions: {
        forLoggedUsers: 0,
        forNotLoggedUsers: 0,
        freeTrialTransactionType: 'refund',
        faqUrl: null,
        promoOnSubs: 1,
        promoOnSingle: 1
      },
      welcomeForm: {
        agreement: '',
        newsletter: ''
      }
    }
  }
}

export default GeneralSettingsForm
