


































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import { DashmixIconName } from '@d24/modules'

import { IToastService, ToastServiceType } from '@service/toaster'

import {
  AbTestsData,
  ISettingsRepository,
  SettingRepositoryType
} from '@module/settings/contracts'
import { FormCheckbox } from '@component/form'

@Component<AbTestsForm>({
  name: 'AbTestsForm',
  components: { FormCheckbox }
})
export class AbTestsForm extends Vue {
  @Inject(SettingRepositoryType)
  protected settingsRepository!: ISettingsRepository

  @Inject(ToastServiceType)
  protected toastService!: IToastService<string, string>

  public readonly icons = DashmixIconName
  public readonly AB_TESTS_KEY: string = 'abTests'
  public formName: string = 'abTests'

  @Prop({
    type: Boolean,
    required: false
  })
  public isLoading!: boolean

  @PropSync('formData', {
    type: Object,
    required: true
  })
  public payload!: AbTestsData

  public async onSubmit (): Promise<void> {
    this.isLoading = true
    try {
    } catch (error) {
      this.toastService.error(error)
    } finally {
      this.isLoading = false
    }
  }

  public static defaultPayload (): AbTestsData {
    return {
      enabled: false,
      ratios: {}
    }
  }
}

export default AbTestsForm
