






























































































































import { AnyObject, EventbusType, IEventbus, IModal, ModalType } from '@movecloser/front-core'
import { Component, Watch } from 'vue-property-decorator'
import {
  DashmixButtonVariantMap,
  DashmixFile,
  DashmixIconName,
  DashmixTheme,
  Picker,
  Related,
  RelatedType,
  SizeMap
} from '@d24/modules'
import { debounce, DebouncedFunc, isEmpty } from 'lodash'

import { Figure } from '@component/Figure/Figure'
import { DashmixSwitch } from '@component/Switch/Switch'
import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@plugin/inversify'

import { Addon } from '../../maps/variant'
import { Attachments, CoverData, Video } from '../../contracts'
import { getImageTypeFromUrl } from '../../helpers/addons'
import { AbstractAddon } from './AbstractAddon'
import { AddonErrors } from './AddonErrors.vue'
import { FileType } from '@d24/modules/src/modules/_contracts'
import { DescriptionOfFile } from '@d24/modules/src/services/related/contracts'
import { VideoPlayer } from '@d24/modules/src/modules/_components/VideoPlayer'

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'CoverAddon',
  components: {
    AddonErrors,
    DashmixFile,
    DashmixSwitch,
    Figure,
    VideoPlayer
  }
})
export class CoverAddon extends AbstractAddon {
  @Inject(EventbusType)
  private eventBus!: IEventbus

  @Inject(ModalType)
  protected modalConnector!: IModal

  public coverData: CoverData = {
    video: {
      url: '',
      onNetworkUrl: '',
      file: undefined,
      playOnTile: false
    },
    extraDistinction: false,
    hideDescription: false
  }

  public galleryTitle: string = ''
  public image: Attachments | null = null
  public useGallery: boolean = false
  public useVideo: boolean = false
  public changeImageData: boolean = false
  private videoDefault: Video = {
    url: '',
    onNetworkUrl: '',
    file: undefined,
    playOnTile: false
  }

  // Helpers

  public buttonTheme = DashmixTheme
  public buttonVariant = DashmixButtonVariantMap
  public icons = DashmixIconName
  public isEmpty: (value?: Attachments) => boolean = isEmpty
  public videoFileDesc: DescriptionOfFile | null = null
  public readonly videoType = [
    {
      value: 'url',
      label: 'URL'
    },
    {
      value: 'file',
      label: 'Plik'
    }
  ]

  public get extraDistinction (): boolean {
    return this.coverData.extraDistinction || false
  }

  public set extraDistinction (value: boolean) {
    this.coverData.extraDistinction = value
    this.setNewData(this.coverData, this.useVideo)
  }

  public get hasImage () {
    return !isEmpty(this.mainPhoto)
  }

  public get hasVideoFile (): boolean {
    return !!this.coverData.video?.file
  }

  public get videoFile (): Related | null {
    return this.coverData.video?.file ?? null
  }

  public get imgType (): string | undefined {
    if (!this.coverData.image || !this.coverData.image.value) {
      return undefined
    }

    if (!this.image) {
      return undefined
    }

    return getImageTypeFromUrl(`${this.image.url}`) || undefined
  }

  public get hasGallery (): boolean {
    return this.useGallery && !!this.coverData.gallery && !!this.coverData.gallery.value
  }

  public get mainPhoto (): Attachments {
    return this.image || {} as Attachments
  }

  public get playOnTile (): boolean {
    return this.coverData.video?.playOnTile || false
  }

  public set playOnTile (value: boolean) {
    if (typeof this.coverData.video !== 'object') {
      this.coverData.video = this.videoDefault
    }

    this.coverData.video.playOnTile = value

    this.setNewData(this.coverData, this.useVideo)
  }

  public deleteGallery () {
    this.coverData.gallery = null

    this.setNewData(this.coverData, this.useVideo)
  }

  public deleteMainPhoto () {
    const newData = { ...this.coverData }
    delete newData.image
    this.coverData = newData
    this.image = null

    this.setNewData(this.coverData, this.useVideo)
  }

  public openFilePicker () {
    let selected
    if (this.mainPhoto.id) {
      selected = {
        value: this.mainPhoto.id,
        type: RelatedType.File
      }
    }
    this.modalConnector.open(Picker.Media, {
      config: { type: 'photo' },
      onClose: () => this.modalConnector.close(),
      onSelection: this.setMainPhoto,
      selected: selected
    }, { size: SizeMap.XLarge })
  }

  public openVideoFilePicker (): void {
    this.modalConnector.open(Picker.Media, {
      config: { allowedMediaType: FileType.Video },
      onClose: () => this.modalConnector.close(),
      onSelection: this.updateVideoFile,
      selected: this.coverData.video?.file
    }, { size: SizeMap.XLarge })
  }

  public onVideoRemove (): void {
    if (!this.coverData.video) {
      return
    }

    this.coverData.video.file = undefined
  }

  public openGalleryPicker () {
    this.modalConnector.open(Picker.Gallery, {
      config: {},
      onClose: () => this.modalConnector.close(),
      onSelection: this.setGallery,
      selected: this.coverData.gallery
    }, { size: SizeMap.XLarge })
  }

  public updateAlt (value: string | undefined) {
    this.coverData = {
      ...this.coverData,
      alt: value
    }

    this.setNewData(this.coverData, this.useVideo)
  }

  public updateCaption (value: string | undefined) {
    this.coverData = {
      ...this.coverData,
      caption: value
    }

    this.setNewData(this.coverData, this.useVideo)
  }

  public updateImageAuthor (value: string | undefined) {
    this.coverData = {
      ...this.coverData,
      imageAuthor: value
    }

    this.setNewData(this.coverData, this.useVideo)
  }

  public updateVideoLink (newLink: string) {
    if (typeof this.coverData.video === 'undefined') {
      this.coverData.video = this.videoDefault
    }
    this.coverData.video.url = newLink

    this.setNewData(this.coverData, this.useVideo)
  }

  public updateOnNetworkUrl (newLink: string) {
    if (typeof this.coverData.video === 'undefined') {
      this.coverData.video = this.videoDefault
    }
    if (newLink.includes('<script')) {
      const match = newLink.match(/src="([^"]+)"/)
      if (match) {
        newLink = match[1]
      }
    }

    this.coverData.video.onNetworkUrl = newLink

    this.setNewData(this.coverData, this.useVideo)
  }

  public async updateVideoFile (newFile: Related) {
    if (typeof this.coverData.video === 'undefined') {
      this.coverData.video = this.videoDefault
    }
    this.coverData.video.file = newFile

    this.setNewData(this.coverData, this.useVideo)
  }

  public toggleHideDescription (value: boolean) {
    this.coverData.hideDescription = value

    this.setNewData(this.coverData, this.useVideo)
  }

  public toggleImageDataOverride (value: boolean): void {
    this.changeImageData = value

    this.coverData = {
      ...this.coverData,
      override: value
    }

    if (!value) {
      this.updateAlt(undefined)
      this.updateCaption(undefined)
      this.updateImageAuthor(undefined)
    }

    this.setNewData(this.coverData, this.useVideo)
  }

  public toggleUseGallery (value: boolean): void {
    this.useGallery = value
    if (value) {
      this.useVideo = false
    } else {
      this.coverData.gallery = null
      this.setNewData(this.coverData, this.useVideo)
    }
  }

  public toggleUseVideo (value: boolean): void {
    this.useVideo = value
    if (value) {
      this.useGallery = false
      this.coverData.video = this.videoDefault
    } else {
      delete this.coverData.video
      this.setNewData(this.coverData, this.useVideo)
    }
  }

  protected async getGalleryTitle (galleryData: Related<RelatedType.Gallery, Identifier>) {
    const gallery: AnyObject = await this.relatedService.describe(galleryData)

    return gallery.title || ''
  }

  protected getVariantProperty (): void {
    if (this.variant) {
      const dataFromModel = this.variant.getProperty<CoverData>(Addon.Cover)

      if (!dataFromModel) {
        this.coverData = {
          video: this.videoDefault,
          extraDistinction: false
        }
        return
      }

      if (typeof dataFromModel?.override === 'undefined') {
        this.toggleImageDataOverride(!!dataFromModel.imageAuthor || !!dataFromModel.caption || !!dataFromModel.alt)
      }

      this.changeImageData = dataFromModel.override!
      this.useVideo = !!dataFromModel.video && (!!dataFromModel.video.url || !!dataFromModel.video.file || !!dataFromModel.video.onNetworkUrl)

      if (!!dataFromModel.gallery && !!dataFromModel.gallery.value) {
        this.useGallery = true
        this.getGalleryTitle(dataFromModel.gallery).then(title => {
          this.galleryTitle = title
        }).catch(e => console.debug(e))
      }

      if (!!dataFromModel.image && !!dataFromModel.image.value) {
        this.setImage(dataFromModel.image)
      }

      this.coverData = { ...this.coverData, ...dataFromModel }
      this.setNewData(this.coverData, this.useVideo)
    }
  }

  protected async setImage (photoData: Related<RelatedType.File, Identifier>): Promise<void> {
    const file: AnyObject = await this.relatedService.describe(photoData)

    this.image = {
      id: file.id,
      caption: file.caption,
      url: file.url
    }
  }

  protected async setGallery (galleryData: Related<RelatedType.Gallery, Identifier>) {
    this.coverData = {
      ...this.coverData,
      gallery: galleryData
    }

    this.setNewData(this.coverData, this.useVideo)

    this.galleryTitle = await this.getGalleryTitle(galleryData)
  }

  protected async setMainPhoto (photoData: Related<RelatedType.File, Identifier>) {
    this.coverData = {
      ...this.coverData,
      image: photoData
    }

    this.setImage(photoData)

    this.setNewData(this.coverData, this.useVideo)
  }

  protected setNewData: DebouncedFunc<(dates: CoverData, useVideo: boolean) => void> =
      debounce((dates: CoverData, useVideo: boolean) => {
        this.setNewData.cancel()
        const dataCopy = JSON.parse(JSON.stringify(dates))

        if (!useVideo) {
          dataCopy.video = {
            url: '',
            playOnTile: false,
            onNetworkUrl: '',
            file: null
          }
        }

        for (const key of Object.keys(dataCopy)) {
          if (typeof dataCopy[key] === 'undefined') {
            delete dataCopy[key]
          }
        }

        this.variant.setProperty<CoverData>(Addon.Cover, dataCopy)

        this.onChange(this.variant)
      }, 1000)

  @Watch('videoFile')
  protected async onVideoFileUpdate (v: Related | null) {
    this.videoFileDesc = v ? await this.relatedService.describe(v) as DescriptionOfFile : null
  }

  public get videoUrl () {
    if (!this.videoFileDesc) {
      return ''
    }
    return this.videoFileDesc.m3u8 ? this.videoFileDesc.m3u8 : this.videoFileDesc.url
  }

  public get videoMime () {
    if (!this.videoFileDesc) {
      return ''
    }
    return this.videoFileDesc.m3u8 ? 'application/x-mpegURL' : `video/${this.videoFileDesc.mime}`
  }

  public get coverVideo () {
    return {
      file: this.videoFileDesc,
      url: this.coverData.video?.url,
      onNetworkUrl: this.coverData.video?.onNetworkUrl
    }
  }
}

export default CoverAddon
