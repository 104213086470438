


























import { Component, Prop, Vue } from 'vue-property-decorator'

import { VariantAddAbTestsModalPayload } from '../contracts'

@Component({
  name: 'VariantAddAbTestsModal'
})
export class VariantAddAbTestsModal extends Vue {
  @Prop({ type: Object, required: true })
  protected payload!: VariantAddAbTestsModalPayload

  public form = {
    count: ''
  }

  public get formCount (): number {
    return Number(this.form.count)
  }

  public get isCountValid (): boolean {
    return this.formCount > 0 && this.formCount <= 10
  }

  public get isValid (): boolean {
    return this.isCountValid
  }

  public onConfirm (): void {
    this.$emit('close')
    this.payload.onConfirm({
      count: Number(this.formCount)
    })
  }

  public closeModal (): void {
    this.$emit('close')
    this.payload.onCancel()
  }
}

export default VariantAddAbTestsModal
