// Copyright © 2021 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'
import { Permission } from '@module/auth/contracts/permissions'

export const resources: ResourcesRegistry = {
  search: {
    prefix: 'api',
    methods: {
      load: {
        url: 'search',
        method: Methods.Get,
        auth: true
      }
    }
  },
  registry: {
    prefix: 'api/management',
    methods: {
      get: {
        url: 'registry/{id}',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      list: {
        url: 'registry',
        method: Methods.Get,
        auth: true
      }
    }
  },
  content: {
    prefix: 'api/management',
    methods: {
      create: {
        url: 'content',
        method: Methods.Post,
        auth: true,
        meta: { toast: true }
      },
      delete: {
        url: 'content/{id}',
        method: Methods.Delete,
        params: ['id'],
        auth: true
      },
      get: {
        url: 'content/{id}',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      list: {
        url: 'content',
        method: Methods.Get,
        auth: true
      },
      nodes: {
        url: 'nodes',
        method: Methods.Get,
        auth: true
      },
      options: {
        url: 'content/{id}/options',
        params: ['id'],
        method: Methods.Put,
        auth: true,
        meta: { toast: true }
      },
      properties: {
        url: 'content/{id}/properties',
        params: ['id'],
        method: Methods.Put,
        auth: true,
        meta: { toast: true }
      },
      order: {
        url: 'content/{parentId}/order',
        params: ['parentId'],
        method: Methods.Post,
        auth: true,
        meta: { toast: true }
      },
      publish: {
        url: 'content/{id}/publication',
        params: ['id'],
        method: Methods.Put,
        auth: true,
        meta: { toast: true }
      },
      stats: {
        url: 'content/stats',
        method: Methods.Get,
        auth: true
      },
      update: {
        url: 'content/{id}',
        params: ['id'],
        method: Methods.Put,
        auth: true,
        meta: { toast: true }
      },
      sendPush: {
        url: 'content/{id}/sendPush',
        params: ['id'],
        method: Methods.Post,
        auth: true,
        meta: { toast: true }
      }
    }
  },
  contentAcceptance: {
    prefix: 'api/management',
    methods: {
      accept: {
        url: 'acceptance/{id}',
        params: ['id'],
        method: Methods.Post,
        auth: true,
        meta: { toast: true }
      },
      get: {
        url: 'acceptance/{id}',
        params: ['id'],
        method: Methods.Get,
        auth: true
      },
      list: {
        url: 'acceptance',
        method: Methods.Get,
        auth: true
      }
    }
  },
  contentComments: {
    prefix: 'api/management',
    methods: {
      accept: {
        url: 'comments/{id}/action',
        params: ['id'],
        method: Methods.Post,
        auth: true
      },
      delete: {
        url: 'comments/{id}',
        params: ['id'],
        method: Methods.Delete,
        auth: true,
        meta: {
          toast: true
        }
      },
      get: {
        url: 'comments/{id}',
        params: ['id'],
        method: Methods.Get,
        auth: true
      },
      list: {
        url: 'comments',
        method: Methods.Get,
        auth: true
      },
      reject: {
        url: 'comments/{id}/action',
        params: ['id'],
        method: Methods.Post,
        auth: true
      }
    }
  },
  contentVariants: {
    prefix: 'api/management',
    methods: {
      create: {
        url: 'content/{content}/variants',
        method: Methods.Post,
        params: ['content'],
        auth: true,
        meta: { toast: true }
      },
      delete: {
        url: 'variants/{id}',
        method: Methods.Delete,
        params: ['id'],
        auth: true
      },
      status: {
        url: 'variants/{id}/status',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      get: {
        url: 'variants/{id}',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      lock: {
        url: 'variants/{id}/lock',
        method: Methods.Put,
        params: ['id'],
        auth: true,
        meta: { toast: false }
      },
      preview: {
        url: 'variants/{id}/preview',
        method: Methods.Post,
        params: ['id'],
        auth: true
      },
      unpublish: {
        url: 'variants/{id}/unpublish',
        method: Methods.Post,
        params: ['id'],
        auth: true
      },
      cancelPublication: {
        url: 'variants/{id}/publication',
        method: Methods.Delete,
        params: ['id'],
        auth: true
      },
      update: {
        url: 'variants/{id}',
        method: Methods.Put,
        params: ['id'],
        auth: true
      },
      abTestsEnable: {
        url: 'variants/{id}/ab-tests/enable',
        method: Methods.Post,
        params: ['id'],
        auth: true
      },
      abTestsAdd: {
        url: 'variants/{id}/ab-tests/add',
        method: Methods.Post,
        params: ['id'],
        auth: true
      },
      abTestsComplete: {
        url: 'variants/{id}/ab-tests/complete',
        method: Methods.Post,
        params: ['id'],
        auth: true
      },
      abTestsPublishAll: {
        url: 'variants/{id}/ab-tests/publish-all',
        method: Methods.Post,
        params: ['id'],
        auth: true
      },
      exportSeoData: {
        url: 'variants/export/seo-data',
        method: Methods.Post,
        auth: true,
        meta: { toast: true, permissions: [Permission.EditSettings] }
      },
      importSeoData: {
        url: 'variants/import/seo-data',
        method: Methods.Post,
        auth: true,
        meta: { toast: true, permissions: [Permission.EditSettings] }
      }
    }
  },
  feeds: {
    prefix: '/api/management',
    methods: {
      list: {
        url: 'feeds',
        method: Methods.Get,
        auth: true
      },
      item: {
        url: 'feeds/{feedId}',
        params: ['feedId'],
        method: Methods.Get,
        auth: true
      },
      delete: {
        url: 'feeds/{feedId}',
        params: ['feedId'],
        method: Methods.Delete,
        meta: { toast: true },
        auth: true
      },
      update: {
        url: 'feeds/{feedId}',
        method: Methods.Put,
        params: ['feedId'],
        meta: { toast: true },
        auth: true
      }
    }
  },
  feedEntries: {
    prefix: '/api/management',
    methods: {
      list: {
        url: 'feeds/{feedId}/entries',
        params: ['feedId'],
        method: Methods.Get,
        auth: true
      },
      item: {
        url: 'feeds/{feedId}/entries/{entryId}',
        params: ['feedId', 'entryId'],
        method: Methods.Get,
        auth: true
      },
      create: {
        url: 'feeds/{feedId}/entries',
        params: ['feedId'],
        method: Methods.Post,
        meta: { toast: true },
        auth: true
      },
      delete: {
        url: 'feeds/{feedId}/entries/{entryId}',
        params: ['feedId', 'entryId'],
        method: Methods.Delete,
        meta: { toast: true },
        auth: true
      },
      update: {
        url: 'feeds/{feedId}/entries/{entryId}',
        params: ['feedId', 'entryId'],
        method: Methods.Put,
        meta: { toast: true },
        auth: true
      }
    }
  },
  public: {
    prefix: 'api',
    methods: {
      registry: {
        url: 'registry/{slug}',
        method: Methods.Get,
        params: ['slug']
      }
    }
  },
  sets: {
    prefix: 'api/management',
    methods: {
      create: {
        url: 'sets',
        method: Methods.Post,
        auth: true,
        shorthand: 'createSet',
        meta: { toast: true }
      },
      delete: {
        url: 'sets/{id}',
        method: Methods.Delete,
        params: ['id'],
        auth: true,
        meta: { toast: true }
      },
      item: {
        url: 'sets/{id}',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      list: {
        url: 'sets',
        method: Methods.Get,
        auth: true
      },
      update: {
        url: 'sets/{id}',
        method: Methods.Put,
        params: ['id'],
        auth: true,
        shorthand: 'editSet',
        meta: { toast: true }
      },
      updateItem: {
        url: 'sets/{setId}/items/{itemId}/meta',
        method: Methods.Post,
        params: ['setId', 'itemId'],
        auth: true,
        meta: { toast: true }
      },
      regenerate: {
        url: 'sets/{id}/regenerate',
        method: Methods.Post,
        params: ['id'],
        auth: true,
        shorthand: 'regenerateSet',
        meta: { toast: true }
      },
      refresh: {
        url: 'sets/{id}/refresh',
        method: Methods.Post,
        params: ['id'],
        auth: true,
        shorthand: 'refreshSet',
        meta: { toast: true }
      }
    }
  },
  setItems: {
    prefix: 'api/management',
    methods: {
      list: {
        url: 'sets/{setId}/items',
        method: Methods.Get,
        params: ['setId'],
        auth: true
      },
      push: {
        url: 'sets/{setId}/items',
        method: Methods.Post,
        params: ['setId'],
        auth: true,
        meta: { toast: true }
      },
      reorder: {
        url: 'sets/{setId}/items',
        method: Methods.Put,
        params: ['setId'],
        auth: true,
        meta: { toast: true }
      },
      remove: {
        url: 'sets/{setId}/items/{itemId}',
        method: Methods.Delete,
        params: ['setId', 'itemId'],
        auth: true,
        meta: { toast: true }
      },
      lock: {
        url: 'sets/{setId}/items/{itemId}/lock',
        method: Methods.Post,
        params: ['setId', 'setId'],
        auth: true
      },
      unlock: {
        url: 'sets/{setId}/items/{itemId}/unlock',
        method: Methods.Post,
        params: ['setId', 'setId'],
        auth: true
      }
    }
  }
}
