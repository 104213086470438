






















import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { DashmixIconName, DropdownItem } from '@d24/modules'

import { Identifier } from '@/shared/contracts/data'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'
import { UserData } from '@module/auth/contracts/models'

import { ContentStatus, SaveVariantMode, VariantModel } from '../contracts'
import { debounce } from 'lodash'
import { Inject } from '@/shared/plugins/inversify'
import { ISiteService, SiteServiceType } from '@module/root/services/site-resolver'

@Component({
  name: 'VariantActions'
})
export class VariantActions extends Mixins<IUserAware>(UserAware) {
  @Inject(SiteServiceType)
  private siteService!: ISiteService

  @Prop({
    type: Object,
    required: true
  })
  protected active!: VariantModel

  @Prop({
    type: Object,
    required: false,
    default: null
  })
  protected authUser!: UserData | null

  @Prop({
    type: Boolean,
    required: false
  })
  public isDisabled!: boolean

  @Prop({
    type: Boolean,
    required: true
  })
  public isGeneratingPreview!: boolean

  @Watch('isDisabled')
  protected onIsDisabled (isDisabled: boolean) {
    if (!isDisabled) {
      this.isSaved = true
      this.toggleIsSavedDebounce()
    }
  }

  public icons = DashmixIconName
  public isSaved = false

  mounted (): void {
    this.toggleIsSavedDebounce = debounce(this.toggleIsSavedDebounce, 2000)
  }

  public toggleIsSavedDebounce (): void {
    this.isSaved = !this.isSaved
  }

  public get saveOptions (): DropdownItem[] {
    const options: DropdownItem[] = []

    if (this.active.status === ContentStatus.Draft) {
      options.push(
        {
          type: 'item',
          icon: DashmixIconName.UserShieldSolid,
          label: `${this.$t('content.saveThenAccept')}`,
          onClick: () => this.saveChanges(SaveVariantMode.Acceptance),
          theme: 'default'
        }
      )
    }

    if (
      this.canPublishContents &&
      ![ContentStatus.Archived, ContentStatus.Rejected, ContentStatus.Unpublished].includes(this.active.status)
    ) {
      options.push(
        {
          type: 'item',
          icon: DashmixIconName.PaperPlaneSolid,
          label: `${this.$t(`content.${this.active.status === ContentStatus.Draft ? 'saveThenPublish' : 'publish'}`)}`,
          onClick: () => this.saveChanges(SaveVariantMode.Publication),
          theme: 'primary'
        }
      )

      if (this.active.hasAbTests && this.siteService.isFeatureEnabled('abTests')) {
        options.push(
          {
            type: 'item',
            icon: DashmixIconName.PaperPlaneSolid,
            label: `${this.$t('content.abTestsPublishAll')}`,
            onClick: () => this.abTestsPublishAll(),
            theme: 'primary'
          }
        )
      }
    }

    if (this.canSendWebPushContents && [ContentStatus.Published].includes(this.active.status)) {
      options.push(
        {
          type: 'item',
          icon: DashmixIconName.BellSolid,
          label: `${this.$t('content.send_web_push')}`,
          onClick: () => this.sendPush(),
          theme: 'success'
        }
      )
    }

    if (this.canPublishContents && [ContentStatus.Published, ContentStatus.AbTestsPublished].includes(this.active.status)) {
      options.push(
        {
          type: 'divider'
        }, {
          type: 'item',
          icon: DashmixIconName.EyeSlashSolid,
          label: `${this.$t('content.unpublish')}`,
          onClick: this.unpublish,
          theme: 'danger'
        }
      )
    }

    return options
  }

  public generatePreview (): void {
    if (this.active.url) {
      window.open(this.active.url)
    } else {
      this.$emit('previewVariant')
    }
  }

  public isEditable (): boolean {
    return this.active.isEditable(this.authUser?.id as unknown as Identifier)
  }

  public saveChanges (action: SaveVariantMode = SaveVariantMode.None): void {
    this.$emit('saveVariant', { postSave: action })
  }

  public sendPush (): void {
    this.$emit('sendWebPush')
  }

  public unpublish (): void {
    this.$emit('unpublish')
  }

  public abTestsPublishAll (): void {
    this.$emit('abTestsPublishAll')
  }
}

export default VariantActions
