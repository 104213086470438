// Copyright © 2021 Move Closer

import { Headers, ICollection } from '@movecloser/front-core'
import { Domain, FeatureFlags, RoutePrefix, SiteModel } from '@module/root/contracts/models'

export interface ISiteResolver {
  boot(): void
  findByDomain (domain: Domain): SiteModel|null
  findBySiteId (siteId: string): SiteModel|null
  getDictionary (): ICollection<SiteModel>
  getSite (): SiteModel|null
  getSiteId<T extends true|false> (force?: T): T extends true ? RoutePrefix : RoutePrefix | undefined
  getSiteAddress(): string
  getSiteHeader (): Headers
  hasSiteSet (): boolean
  setSite (site: SiteModel): void
  setSiteFromParam (param: string): SiteModel
  setupDictionary (collection: ICollection<SiteModel>): void
}

export interface ISiteService {
  boot(): Promise<void>

  refreshSetup(): Promise<void>

  getFeatureFlags (): FeatureFlags

  isFeatureEnabled (feature: keyof FeatureFlags): boolean

  hasAutoAdTags (): boolean|null
}

export const SiteResolverType = Symbol.for('ISiteResolver')
export const SiteServiceType = Symbol.for('ISiteService')
