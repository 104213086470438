// Copyright © 2021 Move Closer

import { DashmixIconName } from '@d24/modules'
import { computed, defineComponent, SetupContext, toRefs } from '@vue/composition-api'

import { modelListHeaderHooks } from './ModelListHeader.hooks'
import { ModelListHeaderMode, ModelListHeaderProps } from '@component/ModelListHeader/ModelListHeader.contracts'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export const ModelListHeader = defineComponent({
  name: 'ModelListHeader',
  props: modelListHeaderHooks,
  setup (props: ModelListHeaderProps, ctx: SetupContext) {
    const { mode } = toRefs(props)

    const isButtonMode = computed(() => mode.value === ModelListHeaderMode.Button)

    const isLinkMode = computed(() => mode.value === ModelListHeaderMode.Link)

    const click = () => ctx.emit('click')

    return { click, DashmixIconName, isButtonMode, isLinkMode }
  },

  template: `
    <div class="w-100">
      <div class="w-100 d-flex justify-content-between">
        <div class="py-4">
          <h1 class="h3 m-0">
            {{ title }}
          </h1>
        </div>
        <div class="d-flex align-items-center">
          <slot name="extra" />
          <slot name="cta">
            <D24Button v-if="isButtonMode" class="btn btn-primary" :label="addLabel" :icon="DashmixIconName.PlusSolid" @click="click" />

            <D24Link v-if="isLinkMode && addTarget && addLabel" class="btn btn-primary" :target="addTarget">
              <D24Icon v-if="!withoutIcon" :icon="DashmixIconName.PlusSolid" :class="{ 'mr-2': addLabel && addLabel.length }" />
              {{ addLabel }}
            </D24Link>
          </slot>
        </div>
      </div>
      <div class="w-100" v-if="this.$slots.default">
        <slot/>
      </div>
    </div>
  `
})
