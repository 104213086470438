// Copyright © 2021 Move Closer

import { Injectable } from '@movecloser/front-core'

import { ISiteResolver, ISiteService } from './contracts'
import { ISitesRepository } from '../../contracts/repositories'
import { FeatureFlags, SetupData } from '../../contracts/models'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Adrian Gajos <adrian.gajos@movecloser.pl> - EDIT
 */
@Injectable()
export class SiteService implements ISiteService {
  private sitesRepository: ISitesRepository
  private siteResolver: ISiteResolver
  private setupData!: SetupData

  public constructor (sitesRepository: ISitesRepository, siteResolver: ISiteResolver) {
    this.sitesRepository = sitesRepository
    this.siteResolver = siteResolver
  }

  public async boot (): Promise<void> {
    const dictionary = await this.sitesRepository.loadCollection()
    this.siteResolver.setupDictionary(dictionary)
    this.siteResolver.boot()
    await this.refreshSetup()
  }

  public async refreshSetup (): Promise<void> {
    this.setupData = await this.sitesRepository.setup()
  }

  public getFeatureFlags (): FeatureFlags {
    return this.setupData.featureFlags
  }

  public isFeatureEnabled (feature: keyof FeatureFlags): boolean {
    return this.setupData.featureFlags[feature]
  }

  public hasAutoAdTags (): boolean|null {
    return this.setupData.autoAdTags
  }
}
