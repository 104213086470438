import { render, staticRenderFns } from "./FileVariantsTable.vue?vue&type=template&id=3921b721&scoped=true&"
import script from "./FileVariantsTable.vue?vue&type=script&lang=ts&"
export * from "./FileVariantsTable.vue?vue&type=script&lang=ts&"
import style0 from "./FileVariantsTable.vue?vue&type=style&index=0&id=3921b721&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3921b721",
  null
  
)

export default component.exports