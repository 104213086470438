




























import { Authentication, AuthServiceType, IModal, ModalType, ResourceActionFailed } from '@movecloser/front-core'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'

import { ConnectorErrors } from '@/shared/exceptions/connector-errors'
import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@/shared/plugins/inversify'
import { Modals } from '@/config/modals'

import { UserModel } from '@module/auth/contracts/models'

import { ContentType, SimpleVariantModel, VariantModel } from '../contracts/models'
import { IVariantsRepository, VariantsRepositoryType } from '../contracts/repositories'
import {
  LockConflictModalPayload,
  RemoveConflictModalPayload,
  VariantAbTestsEnablePayload,
  VariantAbTestsAddPayload,
  VariantAbTestsCompletePayload
} from '../contracts'
import { lockErrorAvatar, lockErrorDate, lockErrorFullName, lockErrorVariantId } from '../helpers/errors'
import { Variant } from '../models/variant'
import { ISiteService, SiteServiceType } from '@module/root/services/site-resolver'

@Component({
  name: 'VariantTabActions'
})
export class VariantTabActions extends Vue {
  @Prop({ type: Object, required: true })
  protected active!: VariantModel

  @PropSync('variants', { type: Array, required: true })
  protected allVariants!: SimpleVariantModel[]

  @Prop({ type: String, required: true })
  protected contentType!: ContentType

  @PropSync('items', { type: Array, required: true })
  protected selectedVariants!: VariantModel[]

  @PropSync('activeTab', { type: String, required: true })
  protected tab!: string

  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(VariantsRepositoryType)
  protected variantsRepository!: IVariantsRepository

  @Inject(SiteServiceType)
  private siteService!: ISiteService

  public icons = DashmixIconName
  public isPending: boolean = false
  public pendingName: string = ''

  public get canBeTaken (): boolean {
    return this.active.isDraft() && !this.active.isEditor(
      this.authService.user?.id as Identifier
    )
  }

  public get canEnableAbTests (): boolean {
    if (!this.siteService.isFeatureEnabled('abTests')) {
      return false
    }
    return this.active.canEnableAbTests && this.active.isEditable(
        this.authService.user?.id as Identifier
    )
  }

  public get canAddAbTests (): boolean {
    if (!this.siteService.isFeatureEnabled('abTests')) {
      return false
    }
    return this.active.canAddAbTests && this.active.isEditable(
        this.authService.user?.id as Identifier
    )
  }

  public get canCompleteAbTests (): boolean {
    if (!this.siteService.isFeatureEnabled('abTests')) {
      return false
    }
    return this.active.canCompleteAbTests && this.active.isPublished()
  }

  public get canEdit (): boolean {
    if (!this.active.isDraft()) {
      return false
    }
    return !this.canBeTaken || !this.isPending
  }

  public get isRemovable (): boolean {
    if (this.allVariants.length === 1) return false

    return this.active.isRemovable(
      this.authService.user?.id as Identifier
    )
  }

  public confirmVariantRemoval () {
    this.modalConnector.open(Modals.Confirm, {
      content: {
        buttonLabel: 'variant.modal.confirm',
        contentText: 'variant.modal.contentText',
        header: this.active.title,
        theme: 'danger'
      },
      onConfirm: () => {
        this.modalConnector.close()
        this.removeVariant()
      },
      onClose: () => this.modalConnector.close()
    })
  }

  public confirmEnableAbTests () {
    this.modalConnector.open(Modals.VariantEnableAbTests, {
      onConfirm: (payload: VariantAbTestsEnablePayload) => {
        this.enableAbTests(payload)
        this.modalConnector.close()
      },
      onCancel: () => this.modalConnector.close()
    })
  }

  public confirmAddAbTests () {
    this.modalConnector.open(Modals.VariantAddAbTests, {
      onConfirm: (payload: VariantAbTestsAddPayload) => {
        this.addAbTests(payload)
        this.modalConnector.close()
      },
      onCancel: () => this.modalConnector.close()
    })
  }

  public confirmCompleteAbTests () {
    this.modalConnector.open(Modals.VariantCompleteAbTests, {
      onConfirm: (payload: VariantAbTestsCompletePayload) => {
        this.completeAbTests(payload)
        this.modalConnector.close()
      },
      onCancel: () => this.modalConnector.close()
    })
  }

  public createVariant (): void {
    this.setPending('creation')

    this.variantsRepository.create(this.$route.params.id as unknown as Identifier).then(variant => {
      this.addNewVariant(variant)
    }).catch((error: ResourceActionFailed) => {
      if (error.status === ConnectorErrors.Conflict) {
        this.handleConflict(error)
      } else {
        console.warn(error)
      }
    }).finally(() => {
      this.setPending('')
    })
  }

  public cloneVariant (): void {
    this.setPending('cloning')

    this.variantsRepository.clone(
      this.$route.params.id as unknown as Identifier,
      this.tab as unknown as Identifier
    ).then(variant => {
      this.addNewVariant(variant)
    }).catch((error: ResourceActionFailed) => {
      if (error.status === ConnectorErrors.Conflict) {
        this.handleConflict(error)
      } else {
        console.warn(error)
      }
    }).finally(() => {
      this.setPending('')
    })
  }

  public async fetchVariant (variantId: number): Promise<void> {
    this.setPending('fetching')
    try {
      const loadedVariant = await this.variantsRepository.load(Number(this.$route.params.id), variantId)
      this.modalConnector.close()
      this.$emit('update:items', [...this.selectedVariants.filter(v => v.id !== variantId), loadedVariant])
      this.$emit('update:activeTab', `${loadedVariant.id}`)
    } catch {} finally {
      this.setPending('')
    }
  }

  public handleConflict (error: ResourceActionFailed): void {
    const modalPayload: LockConflictModalPayload = {
      avatar: lockErrorAvatar(error),
      contentType: this.contentType,
      lockedAt: lockErrorDate(error),
      onClose: this.modalConnector.close,
      onPreview: () => this.fetchVariant(lockErrorVariantId(error)),
      username: lockErrorFullName(error)
    }

    this.modalConnector.open(Modals.LockConflict, modalPayload, { closableWithOutsideClick: true })
  }

  public takeControlOfVariant (): void {
    this.setPending('control')

    this.variantsRepository.lock(
      this.$route.params.id as unknown as Identifier,
      this.tab as unknown as Identifier
    ).then(() => {
      const variant = this.active
      variant.set('editor', this.authService.user)
      this.$emit('update:active', Variant.hydrate(variant.toObject()))
    }).catch((error: ResourceActionFailed) => {
      console.warn(error)
    }).finally(() => {
      this.setPending('')
    })
  }

  protected addNewVariant (variant: VariantModel): void {
    if (!this.selectedVariants.some(v => v.id === variant.id)) {
      this.selectedVariants.push(variant)
    }
    this.tab = `${variant.id}`

    if (!this.allVariants.some(v => v.id === variant.id)) {
      this.allVariants.push(variant.toSimpleVariant())
    }
  }

  protected handleRemovalConflict (error: ResourceActionFailed): void {
    const modalPayload: RemoveConflictModalPayload = {
      contentType: this.contentType,
      lockedAt: lockErrorDate(error),
      onClose: () => this.fetchVariant(this.active.id),
      fullName: lockErrorFullName(error)
    }
    this.modalConnector.open(Modals.RemoveConflictModal, modalPayload, { closableWithOutsideClick: true })
  }

  protected removeVariant (): void {
    this.setPending('removal')

    const id = this.tab
    this.variantsRepository.delete(
      this.$route.params.id as unknown as Identifier,
      id as unknown as Identifier
    ).then(() => {
      for (let i = 0; i < this.selectedVariants.length; ++i) {
        if (`${this.selectedVariants[i].id}` === id) {
          if (this.selectedVariants.length > 1) {
            this.tab = `${this.selectedVariants[i > 0 ? 0 : 1].id}`
          } else {
            this.tab = ''
          }
          this.selectedVariants.splice(i, 1)
        }
      }

      for (let i = 0; i < this.allVariants.length; ++i) {
        if (`${this.allVariants[i].id}` === id) {
          this.allVariants.splice(i, 1)
        }
      }
    }).catch((e: ResourceActionFailed) => {
      if (e.status === ConnectorErrors.Conflict) {
        this.handleRemovalConflict(e)
      } else {
        console.warn(e)
      }
    }).finally(() => {
      this.setPending('')
    })
  }

  protected enableAbTests (payload: VariantAbTestsEnablePayload): void {
    this.setPending('abTests')

    this.variantsRepository.abTestsEnable(
        this.tab as unknown as Identifier,
        payload
    ).then(() => {
      window.location.reload()
    }).catch((error: ResourceActionFailed) => {
      if (error.status === ConnectorErrors.Conflict) {
        this.handleConflict(error)
      } else {
        console.warn(error)
      }
    }).finally(() => {
      this.setPending('')
    })
  }

  protected addAbTests (payload: VariantAbTestsAddPayload): void {
    this.setPending('abTests')

    this.variantsRepository.abTestsAdd(
        this.tab as unknown as Identifier,
        payload
    ).then(() => {
      window.location.reload()
    }).catch((error: ResourceActionFailed) => {
      if (error.status === ConnectorErrors.Conflict) {
        this.handleConflict(error)
      } else {
        console.warn(error)
      }
    }).finally(() => {
      this.setPending('')
    })
  }

  protected completeAbTests (payload: VariantAbTestsCompletePayload): void {
    this.setPending('abTests')

    this.variantsRepository.abTestsComplete(
        this.tab as unknown as Identifier,
        payload
    ).then(() => {
      window.location.reload()
    }).catch((error: ResourceActionFailed) => {
      if (error.status === ConnectorErrors.Conflict) {
        this.handleConflict(error)
      } else {
        console.warn(error)
      }
    }).finally(() => {
      this.setPending('')
    })
  }

  protected setPending (name: string): void {
    this.isPending = name !== ''
    this.pendingName = name
  }
}

export default VariantTabActions
