














import { Component } from 'vue-property-decorator'

import { AbstractAddon } from './AbstractAddon'
import { Addon } from '../../maps/variant'
import { AddonErrors } from './AddonErrors.vue'
import { AdsAddonData } from '@module/content/contracts'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<AdsAddon>({
  name: 'AdsAddon',
  components: { AddonErrors },
  created () {
    const adsFromModel = this.variant.getProperty<AdsAddonData>(Addon.Ads)
    if (!adsFromModel) {
      this.setAdsModel(this.siteService.hasAutoAdTags() ?? true)
    }
  }
})
export class AdsAddon extends AbstractAddon {
  public adsData: AdsAddonData = { autoAds: false }

  protected getVariantProperty () {
    const adsFromModel = this.variant.getProperty<AdsAddonData>(Addon.Ads)
    this.adsData = { ...this.adsData, ...adsFromModel }
  }

  protected setAdsModel (value: boolean) {
    this.adsData.autoAds = value
    this.variant.setProperty<AdsAddonData>(Addon.Ads, this.adsData)
  }
}

export default AdsAddon
